<template>
  <div class="basicParamsPage">
    <Listcontainer
      :searchText.sync="searchText"
      :placeholder="`参数组名称/参数组代码`"
      :multipleSelection="multipleSelection"
      :pagination="pagination"
      @add="add"
      @multipleDel="multipleDel"
      @fetch="loadData"
      :isAdd="true"
      :addText="`添加参数组`"
    >
      <template v-slot:grid
        ><Grid :Gridtype="Gridtype" @intoGrid="view" :GridData="data" :isExport="true" :isUpdate="true" :isDel="true" @add="add" @deleteRow="deleteRow" @editRow="edit" @export="exportgroup"
      /></template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :multi="false" :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
      </template>
    </Listcontainer>
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
import { TABLE } from '/app.config';

const { edit, del } = TABLE.icons;
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid
  },
  data() {
    return {
      Gridtype: 2,
      searchText: '',
      data: [],
      columns: [
        {
          prop: 'name',
          label: '参数名称',
          href: true,
          handle: this.view
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'nestNumber',
          label: '被引用数量',
          align: 'center'
        }
      ],
      pagination: {
        total: 100,
        pageSize: 20,
        currentPage: 1
      },
      operations: {
        width: 200,
        handles: [
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.deleteRow
          }
        ]
      },
      multipleSelection: []
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  methods: {
    async loadData(param = {}) {
      this.Gridtype = 2;
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupType: 1
      };
      const res = await this.$apis.basicParamsGroup.list(data);
      this.data = res.items;
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
    },
    add() {
      this.$router.push({ path: '/basicParams/edit', query: { data: null } });
    },
    async view(item) {
      this.$router.push({ path: '/basicParams/info', query: { data: item } });
    },
    deleteRow(item) {
      this.$confirm('确认要删除已选参数组吗?', '删除参数组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.basicParamsGroup.del({ groupId: item.groupId, type: 2 });
        this.loadData();
        if (fail) {
          return false;
        }
      });
    },
    multipleDel() {
      let groupIds = [];
      this.multipleSelection.forEach(item => groupIds.push(item.groupId));
      this.$apis.basicParamsGroup.del({ groupIds: groupIds.join(',') });
      this.loadData();
    },
    edit(item) {
      this.$router.push({ path: '/basicParams/edit', query: { data: item } });
    },
    async exportgroup(item) {
      const data = {
        groupId: item.groupId
      };
      const res = await this.$apis.basicParamsGroup.exportValidParam(data);
      if (res) {
        this.$apis.basicParamsGroup.ExportParam(data);
      }
    }
  }
};
</script>

<style>
.basicParamsPage {
  height: 100%;
}
</style>
